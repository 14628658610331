// fonts
$baseFontSize: 16;
$baseFont: "Roboto", sans-serif;
$headingFont: "Now";

// color
$darkGray: #2a2a2a;
$white: #fff;
$black: #000;
$magen: #39c0e0;
$magenBold: #165968;
$midGray: #525252;
$blue: #062f6e;

$themePrimaryColor: $magen;
$bodyBgColor: $white;
$sectionBgColor: #edecec;
$textColor: $midGray;
$headingColor: $blue;
